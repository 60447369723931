<template>
    <admin-dashboard-layout>
        <v-data-table
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="verify_users"
            :items-per-page="30"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search"
                    class="mx-4"
                ></v-text-field>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at ? moment(item.created_at).format('LLL') : '' }}
            </template>
            <template v-slot:item.updated_at="{ item }">
                {{ item.updated_at ? moment(item.updated_at).format('LLL') : '' }}
            </template>
            <template v-slot:item.verify_at="{ item }">
                {{ item.verify_at ? moment(item.verify_at).format('LLL') : '' }}
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout"
    import VerifyUsers from "@/models/admin/VerifyUsers"

    /* eslint-disable */
    export default {
        name: "documents-types-index",
        components: {
            AdminDashboardLayout,
        },
        data: function () {
            return {
                loading: false,
                verify_users: [],
                search: '',
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'User', value: 'name' },
                    { text: 'Verified', value: 'verify_at' },
                    { text: 'First Name', value: 'firstname' },
                    { text: 'Last Name', value: 'lastname' },
                    { text: 'Birthday', value: 'birthday' },
                    { text: 'Country', value: 'country' },
                    { text: 'City', value: 'city' },
                    { text: 'Created', value: 'created_at' },
                    { text: 'Updated', value: 'updated_at' },
                ],
            }
        },
        methods: {
            handleClickRow(verify_user) {
                this.$router.push({name: 'admin.verify-users.show', params: {id: verify_user.id}})
            }
        },
        async mounted() {
            this.loading = true
            this.verify_users = await VerifyUsers.get()
            this.loading = false
        },
    }
</script>

<style scoped>

</style>
